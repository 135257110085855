<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        app>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            Dashboard
          </v-list-item-title>
          <v-list-item-subtitle>
            Everything in one place
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
          dense
          nav>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.to"
            link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="" flat>
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <v-toolbar-title>Application</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon
        @click="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <slot/>
      </v-container>
    </v-main>

  </div>
</template>

<script>
export default {
  name: "NavigationLayout",
  data() {
    return {
      items: [
        { title: 'Energy', icon: 'mdi-lightning-bolt', to: '/'},
        // { title: 'Exams', icon: 'mdi-school', to: '/exam' },
        { title: 'Temperature', icon: 'mdi-chart-line', to: '/temperature' },
        { title: 'Pastes', icon: 'mdi-image-multiple', to: '/pastes' },
        // { title: 'Upload', icon: 'mdi-upload', to: '/upload' },
        { title: 'McDonald\'s', icon: 'mdi-food', to: '/mc' },
        { title: 'About', icon: 'mdi-help-box', to: '/about' },
      ],
      drawer: null
    }
  },
  methods: {
    logout() {
      this.$store.commit('setUser', null)
      this.$store.state.ky.post('auth/logout').then(() => {
        this.$router.replace({name: 'Login'})
      })
    }
  }
}
</script>

<style scoped>

</style>